<template>
    <div>
        <div style="position: relative; width: 0; height: 0">
            <div style="position: absolute; left: -100px; top:190px;">
                <!-- https://stackoverflow.com/questions/6040005/relatively-position-an-element-without-it-taking-up-space-in-document-flow -->
                <div class="" v-if="scrollPosition > 0" style="">
                    <img src="@/assets/left.svg" alt="" class="clickable" @click="slide('left')">
                </div>
            </div>
        </div>

        <div class="d-flex justify-center" style="width:100%;">
            <vue-custom-scrollbar tagname="div" class="items" ref="slider" style="overflow: auto; margin-top:29px;">
                <Item class="mx-1 mb-2" v-for="(item, index) of items" v-bind:key="index" :item="item" :purchasable="purchasable" :selectable="selectable" :withdrawable="withdrawable"></Item>
            </vue-custom-scrollbar>
        </div>

        <!-- <div style="margin-left:10px; position:relative;z-index:2"> -->
        <div class="d-inline-block" style="position: relative; width: 0; height: 0">
            <div style="position: absolute; right: -100px; top:100px;">
            <!-- https://stackoverflow.com/questions/6040005/relatively-position-an-element-without-it-taking-up-space-in-document-flow -->
            <div class="" v-if="(scrollPosition < scrollWidth) && scrollWidth != 0">
                <img src="@/assets/right.svg" alt="" class="clickable" @click="slide('right')">
            </div>
            <!-- </div> -->
            </div>
        </div>
    </div>  
</template>

<script>
import vueCustomScrollbar from "vue-custom-scrollbar";
import "vue-custom-scrollbar/dist/vueScrollbar.css";

export default {
    props: [
        'items', 'purchasable', 'selectable', 'withdrawable'
    ],
    data() {
        return {
			scrollPosition: 0,
            scrollWidth: 0,
        }
    },
    components: {
        vueCustomScrollbar,
    },
    async mounted() {
        this.updatePositions();
        
    },
    methods: {
        updatePositions() {
            let element = this.$refs.slider
            if (!element) return;
            this.scrollPosition = element.scrollLeft;
            this.scrollWidth = element.scrollWidth - element.offsetWidth;
        },
        scrollTo(element, scrollPixels, duration) {
            // let element = this.$refs.slider
            if (!element) return;
            const scrollPos = element.scrollLeft;
            this.updatePositions();
            let self = this;
            
            if ( !( (scrollPos === 0 || scrollPixels > 0) && (element.clientWidth + scrollPos === element.scrollWidth || scrollPixels < 0))) {
                const startTime =  "now" in window.performance ? performance.now() : new Date().getTime();
                
                function scroll(timestamp) {
                    const timeElapsed = timestamp - startTime
                    const progress = Math.min(timeElapsed / duration, 1);
                    
                    element.scrollLeft = scrollPos + scrollPixels * progress;

                    if (timeElapsed < duration) {
                        window.requestAnimationFrame(scroll);
                        self.updatePositions();
                    } else {
                        self.updatePositions();
                        return;

                    }
                }
                window.requestAnimationFrame(scroll); // TODO: Not using the timestamp parameter here?
            }
        },
        slide(direction) {
            const amount = 270;
            const tiles = 5;
            direction = direction == 'left' ? -1 : 1;
            let slidable = this.$refs['slider']
            this.scrollTo(slidable, direction * amount * tiles, 0);
            let self = this;
            setTimeout(function() {
                self.updatePositions();
            }, 810);
        },
    },
    computed: {
    }
}
</script>

<style>
.items {
    max-width: 100%;
    width: auto;
    margin: 0 auto;
}
</style>