<template>
  <div class="h-100" style="overflow-y: auto">
    <div class="container">
      <!-- Comes with a transaction  -->
      <!-- This will only support purchased items to begin with -->

      <div v-if="!order || order.state == 'processing' || processing">
        <div class="text-center h2 mt-5">
          Order processing...


          <div style="position:relative; height: 100px">
              <div id="loader"></div>
          </div>
        </div>

        <div class="text-center text-muted">
          Order ID: <span role="textbox" class="input copyme" @click="copy">{{ orderId }}</span>
        </div>
      </div>
      <div v-else>
        <div class="text-center h2 mt-5">
          Order Confirmed!
        </div>
        
        <div class="text-center text-muted">
          Order confirmation number: <span role="textbox" class="input copyme" @click="copy">{{ orderId }}</span>
        </div>
        
      <div v-if="order" class="my-2">
        <div style="box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);">
          <table class="table guard-font table-fixed" style="border-top-right-radius: 10px; border-top-left-radius: 10px;">
            <template v-for="game in sortedByGames">
              <thead v-bind:key="'cart-' + game.id">
                <tr class="thead" style="font-family: Roboto;font-style: normal;font-weight: bold;font-size: 12px;color: #333637;">
                  <th scope="col" style="padding:16px;">{{game.name}} ({{game.items.length}})</th>
                  <th scope="col">
                    <div class="d-flex" style="cursor: pointer;" @click="toggleSort('name')">
                      <div style="font-family: Roboto;font-style: normal;font-weight: bold;font-size: 12px;color: #333637;">Item Name</div>
                      <div class="ml-2 d-flex flex-column">
                        <font-awesome-icon icon="caret-up" style="margin-top:-3px;" :style="{ 'color': sort == 'name-asc' ? '#ED6B33' : '#B0B0B0' }" />
                        <font-awesome-icon icon="caret-down" style="margin-top:-3px;" :style="{ 'color': sort == 'name-desc' ? '#ED6B33' : '#B0B0B0' }"/>
                      </div>
                    </div>
                  </th>
                  <th scope="col" style="padding:16px;">Item Info</th>
                  <th scope="col" style="">
                    <div class="d-flex" style="cursor: pointer;" @click="toggleSort('quantity')" >
                      <div style="margin-left:10px; font-family: Roboto;font-style: normal;font-weight: bold;font-size: 12px;color: #333637;">Quantity</div>
                      <div class="ml-2 d-flex flex-column">
                        <font-awesome-icon icon="caret-up" style="margin-top:-3px;" :style="{ 'color': sort == 'quantity-asc' ? '#ED6B33' : '#B0B0B0' }" />
                        <font-awesome-icon icon="caret-down" style="margin-top:-3px;" :style="{ 'color': sort == 'quantity-desc' ? '#ED6B33' : '#B0B0B0' }" />
                      </div>
                    </div>
                  </th>
                  <th scope="col" style="">
                    <div class="d-flex" style="cursor: pointer;" @click="toggleSort('price')">
                      <div style="font-family: Roboto;font-style: normal;font-weight: bold;font-size: 12px;color: #333637;">Price</div>
                      <div class="ml-2 d-flex flex-column">
                        <font-awesome-icon icon="caret-up" style="margin-top:-3px;" :style="{ 'color': sort == 'price-asc' ? '#ED6B33' : '#B0B0B0' }" />
                        <font-awesome-icon icon="caret-down" style="margin-top:-3px;" :style="{ 'color': sort == 'price-desc' ? '#ED6B33' : '#B0B0B0' }" />
                      </div>
                    </div>
                  </th>
                  <th scope="col" style="padding:16px;">Select</th>
                </tr>
              </thead>
              <tbody v-bind:key="game.id">
                <tr v-for="item of game.items" v-bind:key="item.id">
                  <td class="ib item-image" style="width: auto"><img :src="'https://steamcommunity-a.akamaihd.net/economy/image/' + item.image + '/256fx256f'" alt="" style="width: auto; height: 70px"></td>
                  <td class="ib item-name" style="vertical-align: middle;">
                    <div>
                      <div class="item-title" style="text-decoration: underline;cursor: pointer;" @click="openSellModal(item.id)">{{item.name}}</div>
                      <div class="item-status">
                          <div v-if="item.stattrak" class="item-stattrak">StatTrak™</div> 
                          <!-- <span v-if="item.stattrak && item.wear"> | </span> -->
                          <div v-if="item.wear" class="item-wear">{{item.wear}} </div>
                      </div>
                    </div>
                  </td>
                  <td class="ib item-desc" style="vertical-align: middle;">
                    <div v-if="item.stickers">
                      <span v-for="(sticker, i ) in item.stickers" v-bind:key="i"><img :src="sticker.image" style="height:24px" alt=""></span>
                    </div>
                    <div v-if="item.float && item.float.paintwear" style="font-family: Roboto;font-style: normal;font-weight: normal;font-size: 10px; letter-spacing: 0.2px;color: #484848;">Float Value: <span>{{item.float.toFixed(4)}}</span></div>
                    <div v-else style="font-family: Roboto;font-style: normal;font-weight: normal;font-size: 10px; letter-spacing: 0.2px;color: #484848;">Float Value: <span>Not Available</span></div>
                    <div v-if="item.inspected" style="font-family: Roboto;font-style: normal;font-weight: normal;font-size: 10px; letter-spacing: 0.2px;color: #484848;">Pattern Index: <span>{{item.inspected.paintseed}}</span></div>
                  </td>
                  <td class="ib" style="vertical-align: middle;">                      
                    <div class="input-group mt-1" style="width:77px; border:0">
                      <input
                        type="text"
                        class="form-control text-center"
                        value="1"
                        style="width: 10px; border-radius1: 0; border-right:0;border-left:0; color:black; padding-left: 4px; padding-right:4px;font-family: Roboto;font-style: normal;font-weight: bold;font-size: 16px;color: #222222; border: 1px solid #c3c3c3; border-radius:25px;"
                        v-model="item.qty"
                      />
                    </div>
                  </td>
                  <td class="ib" style="font-family: Roboto;font-style: normal;font-weight: bold;font-size: 14px;color: #96C77E;vertical-align: middle;">${{ item.listPrice.toFixed(2) }}</td>
                  <td class="ib" style="vertical-align: middle;">
            
                    <label class="checkmark-container lg" style="width:20px; font-size: 14px; height: 30px">
                      <input type="checkbox" @click="select(item)" :checked="selectedItems.findIndex(x=> x.id == item.id) > -1"/>
                      <span class="checkmark"></span>
                    </label>
                  </td>
                </tr>
              </tbody>
            </template>
          </table>
        </div>
      </div>

      <div v-else class="my-5">
        <div class="loader mx-auto mt-2"></div>
      </div>

      </div>
      
      <div class="row">
        <div class="d-flex mx-auto w-auto text-center">
          <button class="btn btn-primary offset-3 mx-2" :disabled="!order" @click="relistSelected">Re-list selected</button>
          <button class="btn btn-secondary offset-3 mx-2" :disabled="!order" @click="withdrawSelected">Withdraw selected</button>
        </div>
      </div>

      <div class="text-muted text-center mt-4">All items are available in your site inventory to relist or withdraw at any time.</div>

      <div class="modal modal-fade" tabindex="-1" role="dialog" id="sellmodal">
        <div class="modal-dialog modal-md modal-dialog-centered" role="document">
            <div class="modal-content"  v-if="selectedItem">
                <div class="modal-header text-center">

                      <h6 class="modal-title col-12 text-center d-flex">
                        <div style="left: 0px; height: 0px; position: absolute; top: -13px;"><img :src="'https://steamcommunity-a.akamaihd.net/economy/image/' + selectedItem.image + '/256fx256f'" alt="" style="height:60px;"></div>
                        <div class="px-2" style="margin-left:45px; margin-top:5px;" v-resize-text="{ 'ratio': 2.5 }">{{ selectedItem.market_hash_name }} ({{ selectedItemCount }})</div>

                        
                        <div class="item-tooltip-container">
                          <span class="item-name">
                            <div class="badge my-auto ml-2">
                              <img src="@/assets/info.svg" alt />
                            </div>
                          </span>
                          <Item class="item-tooltip" :item="selectedItem" :ref="selectedItem.id"></Item>
                        </div>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                      </h6>
                </div>
                <div class="modal-body p-3">
                  <div class="d-flex justify-content-center my-2">
                    <div class="d-flex">
                        <div class="input-group-prepend">
                            <span 
                                class="input-group-text brl" 
                                id="basic-addon1" 
                                style="
                                    font-size: 1.3rem;
                                    border-top-right-radius: 0px;
                                    border-bottom-right-radius: 0px;
                                "
                            >$</span>
                        </div>
                        <currency-input
                            :value="selectedItem.listPrice"
                            @change="setItemPrice(selectedItem.id, $event)"
                            v-on:enter="closeSellModal"
                            class=""
                            style="
                                font-size: 1.3rem;
                                text-align: center;
                                border-top-right-radius: 15px; 
                                border-bottom-right-radius: 15px; outline: none;
                                border: 1px solid #ced4da;
                                background-color: #e9ecef;
                                color: #7BAA64;
                            "
                            :options="{
                                currency: 'USD',
                                currencyDisplay: 'hidden',
                                hideCurrencySymbolOnFocus: false,
                                hideGroupingSeparatorOnFocus: false,
                                hideNegligibleDecimalDigitsOnFocus: false,
                                useGrouping: true,
                                valueRange: { min: 0, max: 100000 },
                            }"
                        />
                    </div>
                    <button class="btn btn-orange ml-3" @click="closeSellModal">CONFIRM</button>
                  </div>

                  <div class="d-flex justify-content-around mt-3">
                    <div>
                      <div class="mx-1">
                        <div class="mb-2" style="border: 1px solid #606060; border-radius: 5px; padding: 5px 0px; background: #f3f3f3;">
                          <div style="font-family: Roboto; font-size: 0.8 rem;font-weight: bold; text-align: center;">Average Sale</div>
                          <div style="font-family: Roboto; font-size: 1.2rem; color: #7BAA64; font-weight: bold; text-align: center">{{numeral(avgPrice).format('$0,0.00')}}</div>
                        </div>
                        <button class="btn btn-block" @click="changeListPrice(selectedItem, avgPrice);" :disabled="!avgPrice" :class="avgPrice ? 'btn-teal' : 'btn-dark'" >MATCH</button>

                      </div>
                    </div>
                    <div>
                      <div class="mx-1">
                        <div class="mb-2" style="border: 1px solid #606060; border-radius: 5px;  padding: 5px 0px; background: #f3f3f3;">
                          <div style="font-family: Roboto; font-size: 0.8 rem;font-weight: bold; text-align: center;">Lowest Listing</div>
                          <div style="font-family: Roboto; font-size: 1.2rem; color: #7BAA64; font-weight: bold; text-align: center">{{numeral(minPrice).format('$0,0.00')}}</div>
                        </div>
                        <button class="btn btn-block" @click="changeListPrice(selectedItem, minPrice);" :disabled="!minPrice" :class="minPrice ? 'btn-teal' : 'btn-dark'" >MATCH</button>
                      </div>
                    </div>
                    <div>
                      <div class="mx-1">
                        <div class="mb-2" style="border: 1px solid #606060; border-radius: 5px;  padding: 5px 0px; background: #f3f3f3;">
                          <div style="font-family: Roboto; font-size: 0.8 rem;font-weight: bold; text-align: center;">Highest Buyorder</div>
                          <div style="font-family: Roboto; font-size: 1.2rem; color: #7BAA64; font-weight: bold; text-align: center">{{numeral(orderPrice).format('$0,0.00')}}</div>
                        </div>
                        <button class="btn btn-block" @click="changeListPrice(selectedItem, orderPrice);" :disabled="!orderPrice" :class="orderPrice ? 'btn-teal' : 'btn-dark'" >MATCH</button>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
// Swal = Swal.mixin({ heightAuto: false, })

import * as mock from '@/mock.js';
import ScrollView from '../components/ScrollView.vue';

import api from "@/api.js";
import events from "@/events.js";
import numeral from 'numeral';
import parseItems from "../utils/parseItems";

export default {
  data() {
    return {
      orderId: "",
      order: null,
      items: [],
      selectedItems: [],
      selectedItemId: null,
      nextItemIds: [],
      allPricesSet: false, 
      selectedItemCount: 0,
      processing: true,
    }
  },
  async mounted() {
    try {
      this.$store.commit('cart/clearCart');
    } catch (e) {
      return console.error("Unable to clear cart");
    }

    // Pull the order.
    let orderId = this.$route.params.orderId;
    this.orderId = orderId;
    let userId = this.user.id;

    console.log(`Loading order ${userId} - ${orderId}`);
    let order   = (await api.getOrder({ orderId, userId })).data;
    let items = await parseItems((await api.getItems({ ids: order.items.map(x=>x.id).join(','), userId })).data);
    this.order = order;

    if (order.state == 'PROCESSING') {
      try {
        console.log("Confirming purchase " + orderId);
        let { data: { error  } } = await api.post(`/ConfirmPurchase`, { orderId: orderId });
        if (error) {
          await Swal.fire({
            title: `Error`,
            text: "We're sorry, but the items you are trying to purchase are no longer available.",
            icon: "error",
            heightAuto: false,
          });
        }

        try {
          await this.$store.dispatch("cart/updateCart")
        } catch (e) { console.log(`Unable to update cart` ) }
        try {    
          let user = this.$store.getters["auth/user"];
          await this.$store.dispatch("auth/refreshUser", user);
        } catch (e) { console.log(`Unable to update user` ) }

        try {
          this.$store.commit('cart/clearCart');
          console.log("Hello")
        } catch (e) {
          return console.error("Unable to clear cart");
        }
        if (orderId) {
          order   = (await api.getOrder({ orderId, userId })).data;
          
          this.order = order;
        }
      } catch (e) {

        await Swal.fire({
          title: `Error`,
          text: "We're sorry, but the items you are trying to purchase are no longer available.",
          icon: "error",
          heightAuto: false,
        });
      }
      try {
        items = await parseItems((await api.getItems({ ids: order.items.map(x=>x.id).join(','), userId })).data);
      } catch (e) {
        
      }
    }

    this.items = items.map(item => { 
      item.qty = item.qty || 1;
      // item.listPrice = 123
      return item 
    });
    
    this.selectedItems = JSON.parse(JSON.stringify(this.items));

    this.processing = false;

    events.$on('withdrawalOrderRetry', () => {
      this.withdrawSelected();
    });
  },
  components: {
    ScrollView

  },
  computed: {
    avgPrice() { try { return this.$refs[this.selectedItemId][0].avgPrice } catch (e) { return 0 } },
    minPrice() { try {  return this.$refs[this.selectedItemId][0].minPrice } catch (e) { return 0 }  },
    orderPrice() { try {  return this.$refs[this.selectedItemId][0].orderPrice } catch (e) { return 0 }  },
    selectedItem() { 
      return this.items.find(x => x.id == this.selectedItemId)
    },
    user() {
      return this.$store.getters['auth/user'];
    },
    sort() {
      return this.sortColumn + '-' + this.sortDirection;
    },
    sortedByGames() {
      console.log("Recomputed sorted by games")
      let itemsByGameId = {};
      for (let item of this.items) {
        let appId = item.appId || item.steamItem.appId || item.steamItem.appid;
        appId = appId+'';
        itemsByGameId[appId] = itemsByGameId[appId] || [];
        itemsByGameId[appId].push(item);
      }

      let games = [ 
        { id: 730, name: 'CS:GO', path: "/csgo", items: itemsByGameId['730'] || [] }, 
        { id: 440, name: 'TF2', path: "/tf2", items: itemsByGameId['440'] || [] },
        { id: 570, name: "Dota 2", path: "/dota2", items: itemsByGameId['570'] || [] },
        { id: 252490, name: "Rust", path: "/rust", items: itemsByGameId['252490'] || [] },
        // { id: 433850, name: "Z1:BR", path: "/z1br", items: itemsByGameId['433850'] || [] }
      ];

      return games.filter(game => game.items.length > 0)
    },
  },
  methods: {
    numeral,
    setItemPrice(itemId, listPrice) {
      console.log("Setting price");
      this.items.find(x => x.id == itemId).listPrice = listPrice;
      this.selectedItems.find(x => x.id == itemId).listPrice = listPrice;
      this.selectedItem.listPrice = listPrice;
    },
    openSellModal(itemId) {
      this.selectedItemId = itemId;
      this.selectedItemCount++;
      // Open the modal
      $('#sellmodal').modal('show');
    },
    closeSellModal() {
      console.log("clicked close");

      // Could refactor allPricesSet into a computed property with array length maybe.
      if (this.nextItemIds.length == 0) this.allPricesSet = true;
      

      if (this.nextItemIds.length > 0) {
        // We need to show the next
        console.log("showing next item");

        let itemId = this.nextItemIds.splice(0, 1)[0];

        // After splicing, are all prices set?
        if (this.nextItemIds.length == 0) {
          this.allPricesSet = true;
        }

        this.openSellModal(itemId);
        return;
      }
        
      console.log("Hiding modal")
      this.selectedItemId = null;
      $('#sellmodal').modal('hide');

      if (this.allPricesSet) {
        console.log("saving modal")
        this.relistSelected();
      }
    },
    copy() {
      try {
        document.execCommand('copy')
      } catch (e) {
        console.log("Unable to copy.")
      }
    },
    toggleSort(column) {
      if (column == this.sortColumn) {
        if (this.sortDirection == 'asc') {
          this.sortDirection = 'desc'
          return;
        }
        if (this.sortDirection == 'desc') {
          this.sortDirection = 'asc'
          return;
        }
      } else {
        this.sortDirection = 'desc';
        this.sortColumn = column;
        return;
      }
    },
    select(item) {
      console.log("What");
      let hasItem = this.selectedItems.findIndex(x=>x.id == item.id);

      if (hasItem > -1) {
        console.log("Trying to remove")
        this.selectedItems.splice(hasItem, 1);
      } else {
        console.log("Trying to addd")
        this.selectedItems.push(item);
      }
    },
    async withdrawSelected() {

      if (this.$store.getters["auth/user"].state == "HARD_BANNED") {
        return Swal.fire('Account Disabled', "Please contact support", 'danger');
      }

      this.$store.dispatch('stepByStep/startWithdrawal');

      if(this.selectedItems.length == 0) {
        this.$store.commit('stepByStep/setError', {
          retryable: false,
          message: `You have not selected any items!`,
        });
      } else if (!this.user.tradeOfferUrl) {
        this.$store.commit('stepByStep/setError', {
          retryable: false,
          message: `You have not set a tradeofferurl in your settings!`,
        });
      } else {
        console.log("Got into else");
        try {
          let response = await api.withdrawItems(this.selectedItems);

          // this.$store.dispatch('stepByStep/clear');
          this.$store.commit('stepByStep/updateTradeoffers', response.data.tradeoffers);
          this.$store.commit('stepByStep/completeStep');
        } catch (e) {
          // console.error(`API failrue on withdarw: ${e.response.data.message}`);
          let error = `We failed to process some of your selected items.`
          if (e.response && e.response.data && e.response.data.errors && e.response.data.errors[0]) {
            error = e.response.data.errors[0];
          }
          if (e.response && e.response.data && e.response.data.message) {
            error = e.response.data.message;
          }

          console.log("Sad times");
          console.error(e);
          this.$store.commit('stepByStep/setError', {
            retryable: true,
            retryEvent: "withdrawalOrderRetry",
            message: error,
          });
        }
      }
    },
    async relistSelected() {
      this.selectedItemCount = 0;
      if (!this.allPricesSet) {
        console.log("Not ")
        this.nextItemIds = JSON.parse(JSON.stringify(this.selectedItems.map(x => x.id)));
        return this.openSellModal(this.nextItemIds.splice(0, 1)[0]);
      }

      try {
        for (let item of this.selectedItems) {
          // debugger
          await this.$store.dispatch('items/updateListing', {
            partitionKey: item.depositerId,
            resourceId: item.id,
            changes: [{          
              key: "state",
              value: "LISTED",
              oldValue: "UN-LISTED"
            }, {          
              key: "listPrice",
              value: item.listPrice,
            }]
          });
        }

        Swal.fire({
          title: `Relisted`,
          text: `We've relisted ${this.selectedItems.length} items`,
          icon: "success",
          heightAuto: false,
        });
      } catch (e) {
        console.error(e)
        this.allPricesSet = false;
        Swal.fire({
          title: `Error`,
          text: `There was an error while re-listing one or more item`,
          icon: "error",
          heightAuto: false,
        });
      }
    },
    redirect() {
      let game = this.sortedByGames.find(x => x.items.length > 0);
      this.$router.push(game.path)
    }
  },
}
</script>

<style lang="scss" scoped>
  @import '@/scss/item.scss';
  // Loader
.loader {
  // position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 70px;
  height: 70px;
  margin: -35px 0 0 -35px;
  border: 8px solid #4e4e4e;
  border-radius: 50%;
  border-top: 8px solid $primary;
  // width: 120px;
  // height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
/* Add animation to "page content" */
.animate-bottom {
  position: relative;
  -webkit-animation-name: animatebottom;
  -webkit-animation-duration: 1s;
  animation-name: animatebottom;
  animation-duration: 1s
}

@-webkit-keyframes animatebottom {
  from { bottom:-100px; opacity:0 } 
  to { bottom:0px; opacity:1 }
}

@keyframes animatebottom { 
  from{ bottom:-100px; opacity:0 } 
  to{ bottom:0; opacity:1 }
}

.copyme {
  border-radius: 5px;
  background-color: #cdcdcd;

  padding-left: 10px;
  padding-right: 10px;
  margin-left: 10px;
  
  width: auto;
  display: inline-block;
  color: black;
  -webkit-user-select: all;  /* Chrome 49+ */
  -moz-user-select: all;     /* Firefox 43+ */
  -ms-user-select: all;      /* No support yet */
  user-select: all;          /* Likely future */  

  cursor: pointer;
}

.item-image {
  padding-bottom: 10px;
}

.item-name {
  min-width: 150px;
}
.item-title {
  max-width: 100%;
}

/* Tooltip container */
.item-tooltip-container {
  position: relative;
  display: inline-block;
  /* border-bottom: 1px dotted black; If you want dots under the hoverable text */
  cursor: pointer;
}

/* Tooltip text */
.item-tooltip-container .item-tooltip {
  visibility: hidden;
  /* width: 120px; */
  /* background-color: black; */
  /* color: #fff; */
  /* text-align: center; */
  /* padding: 5px 0; */
  /* border-radius: 6px; */
 
  /* Position the tooltip text - see examples below! */

  top: 0%;
  left: 0px; /* To the left of the tooltip */
  position: absolute;
  z-index: 1;
}

.item-tooltip-container:hover{
  border-bottom: 1px solid white; 

}
/* Show the tooltip text when you mouse over the tooltip container */
.item-tooltip-container:hover .item-tooltip {
  visibility: visible;
}
</style>